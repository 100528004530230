import { camelizeKeys, decamelizeKeys, type HumpsOptions } from 'humps'

const humpsConfig: Pick<HumpsOptions, 'separator' | 'process'> = {
  separator: '_',
  // Позволяет не трогать ключи написанные CAPS-LOCK'ом
  process: function(key, convert, options) {
    return /^[A-Z]+$/.test(key) ? key : convert(key, options)
  }
}

const decamelizeBody = (body: any) => {
  if (!body) {
    return undefined
  }

  try {
    return decamelizeKeys(body, humpsConfig)
  } catch {
    return body
  }
}
/**
 * Базовый клиент для запросов.
 * Добавляет camelize/decamelize функционал по флагу camelize передаваемый в options.
 * По дефолту camelize включен.
 * @see FetchOptions
 * @see FetchOptions.camelize
 * */
export default $fetch.create({
  retry: 0,
  responseType: 'json',
  retryStatusCodes: [],
  onRequest(ctx) {
    ctx.options.baseURL = useRuntimeConfig().public.mfUrl + '/api/landing/'

    if (ctx.options.body && ctx.options.camelize !== false) {
      ctx.options.body = decamelizeBody(ctx.options.body)
    }
  },
  onResponse(ctx) {
    if (ctx.options.camelize !== false) {
      ctx.response._data = camelizeKeys(ctx.response._data, humpsConfig)
    }
  },
  headers: {
    'Content-Type': 'application/json'
  }
})
